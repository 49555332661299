import React from "react";
import Link from "next/link";
import ScrollTop from "./ScrollTop";

const linkItemSections = [
  {
    id: 4,
    title: "Company",
    quicklink: [
      {
        id: 1,
        text: "About",
        url: "/about-us",
      },
    ],
  },
];

function FooterLinkItem({ url, text }: { url: string; text: string }) {
  return (
    <li key={url}>
      <Link href={url}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a>{text}</a>
      </Link>
    </li>
  );
}

function Footer() {
  return (
    <>
      <footer className="rn-footer footer-style-default no-border">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              {linkItemSections.map((section) => (
                <div
                  key={section.id}
                  className="col-lg-2 col-md-6 col-sm-6 col-12"
                >
                  <div className="rn-footer-widget">
                    <h4 className="title">{section.title}</h4>
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {section.quicklink.map((data) => (
                          <FooterLinkItem
                            key={data.id}
                            url={data.url}
                            text={data.text}
                          />
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="copyright-area copyright-style-one no-border">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="copyright-left">
                  <ul className="ft-menu link-hover">
                    <li>
                      <Link href="/terms/privacy">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a>Privacy Policy</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/terms">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a>Terms And Condition</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/contact-us">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a>Contact Us</a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="copyright-right text-center text-md-end">
                  <p className="copyright-text">© Modulabs 2022</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScrollTop />
    </>
  );
}

export default Footer;
