import React, { useState, useRef } from "react";
import { FiMenu } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from "./Nav";
import MobileMenu from "./MobileMenu";
import DarkMode from "./DarkMode";
import useStickyHeader from "./useStickyHeader";

export type HeaderProps = {
  className: string;
};

function Header({ className }: HeaderProps) {
  const [offCanvasShow, setOffCanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffCanvasShow((prev) => !prev);
  };
  const headerRef = useRef();
  const check = true;
  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky && check ? "sticky" : ""}`;

  return (
    <>
      <header
        ref={headerRef}
        className={`rn-header header-default ${className} ${headerClasses}`}
      >
        <div className="container position-relative">
          <div className="row align-items-center row--0">
            <div className="col-lg-3 col-md-6 col-4">
              <Logo />
            </div>
            <div className="col-lg-9 col-md-6 col-8 position-static">
              <div className="header-right">
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>
                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div className="hamberger">
                    <span
                      className="hamberger-button"
                      onClick={onCanvasHandler}
                    >
                      <FiMenu />
                    </span>
                  </div>
                </div>
                <DarkMode />
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu show={offCanvasShow} onClose={onCanvasHandler} />
    </>
  );
}
export default Header;
