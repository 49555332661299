import React from "react";
import Link from "next/link";

export default function Nav() {
  return (
    <ul className="mainmenu">
      <li className="has-droupdown">
        <Link href="/">Home</Link>
      </li>
      <li>
        <Link href="/about-us">About</Link>
      </li>
      <li className="has-droupdown">
        <Link href="/contact-us">Contact</Link>
      </li>
    </ul>
  );
}
