import React from "react";
import Link from "next/link";
import Image from "next/image";
import lightImage from "../../../public/images/logo/logo.png";
import darkImage from "../../../public/images/logo/logo-dark.png";

function Logo() {
  return (
    <div className="logo">
      <Link href="/">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a>
          <div className="flex-fill logo-light">
            <Image
              layout="responsive"
              src={lightImage}
              alt="Modulabs Logo"
              placeholder="blur"
              priority
            />
          </div>
          <div className="flex-fill logo-dark">
            <Image
              layout="responsive"
              src={darkImage}
              alt="Modulabs Logo"
              placeholder="blur"
              priority
            />
          </div>
        </a>
      </Link>
    </div>
  );
}

export default Logo;
