import React from "react";

function DarkMode() {
  const switchTheme = () => {
    document.querySelector("body").classList.toggle("light");
  };
  return (
    <button id="darkmode" onClick={switchTheme}>
      <img
        className="light-icon"
        src="/images/icons/sun-01.svg"
        alt="Sun images"
      />
      <img
        className="dark-icon"
        src="/images/icons/vector.svg"
        alt="Sun images"
      />
    </button>
  );
}
export default DarkMode;
