import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

function SectionTitle({
  subtitle,
  title,
  description,
  textAlign,
  radiusRounded,
}) {
  return (
    <div className={`section-title ${textAlign}`}>
      <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce>
        <h4 className={`subtitle ${radiusRounded}`}>
          <span
            className="theme-gradient"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </h4>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce>
        <h2
          className="title w-600 mb--20"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce>
        <p className="description b1">{description}</p>
      </ScrollAnimation>
    </div>
  );
}
export default SectionTitle;
